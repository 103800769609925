.form {

    margin: 0 auto;
    max-width: 800px;

    label {
        padding-bottom: 8px;

        border-right: 1px solid lighten($gray-light, 25);
        text-align: right;
        text-transform: uppercase;
    }

    input {
        position: relative;
        top: 2px;
    }

    textarea {
        height: 80px;
    }

    button {
        float: right;

        @include media-breakpoint-up(sm) {
            margin-right: -15px;
        }
    }

    .form-group {
        background-color: $gray-lighter;

        &:last-of-type {
            background-color: #fff;
        }
    }
}