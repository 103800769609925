.contact-header {

    text-align: center;

    &__heading {

        @extend .h1;
        color: $orange;

        @include media-breakpoint-up(lg) {
            font-size: 3.75em;
        }
    }

    &__copy {

        margin: 0 auto 40px;
        max-width: 880px;
    }

    &__phone {

    }
}