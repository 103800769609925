.site-footer {
    padding: 20px 0;

    background-color: $blue-dark;
    font-size: 16px;
    text-align: center;

    a {
        font-size: 12px;
        opacity: .3;
        text-decoration: none;

        &:hover, &:focus {

            outline: 0;
            opacity: 1;
        }
    }
}