.detail-header {

    @media (min-width: 768px) {

        left: 20px;
        position: absolute;
        top: 20px;
    }

        @media (min-width: 1200px) {

            left: calc(50vw - 580px);
        }

    &--stuck {

        @media (min-width: 768px) {

            position: fixed;
            top: 110px;
        }
    }

    &__inner {

        position: relative;

        max-width: 500px !important;
        overflow: hidden;
        padding: 0 20px 70px;
        color: $brand-primary;

        &:before {
            position: absolute;
            left: -50%;
            bottom: 50px;

            content: '';
            height: 200%;
            width: 200%;

            background-color: rgba(#fff, .9);
            transform: rotate(-11deg)
        }

        @include media-breakpoint-up(md) {
            width: 40%;
        }

    }

    &__heading {

        position: relative;
        z-index: 1;

        line-height: 1.15;
    }

    &__copy {

        position: relative;
        z-index: 1;

        color: darken($blue, 32);

        strong {
            color: $blue;
        }
    }
}