.body {

    padding-top: 50px;

    background-color: #ddd;
    color: #fff;

    @include media-breakpoint-up(md) {

        padding-top: 93px;
    }

    @media (max-height: 599px) {

        padding-top: 50px;
    }
}