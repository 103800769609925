/* ==========================================================================
   Responsivie Debugging
   ========================================================================== */

  body.development main:hover:after {
    color: #FFF;
    font-size: 20px;
    padding: 5px;
    font-weight: bold;
    right: 10px;
    position: fixed;
    text-align: center;
    bottom: 10px;
    width: 170px;
    z-index: 3000;
    opacity: .5;

    @include media-breakpoint-up (xs) {
      background: lighten($brand-primary, 5%);
      content: "(xs)";
    }

    @include media-breakpoint-up (sm) {
      background: lighten($brand-primary, 5%);
      content: "(sm)";
    }

    @include media-breakpoint-up (md) {
      background: lighten($brand-primary, 5%);
      content: "(md)";
    }

    @include media-breakpoint-up (lg) {
      background: lighten($brand-primary, 10%);
      content: "(lg)";
    }

    @include media-breakpoint-up (xl) {
      background: lighten($brand-primary, 15%);
      content: "(xl)";
    }

  }




