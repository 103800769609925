.hero {

    background-color: #ddd;
    border-bottom: 8px solid #fff;

    &__inner {

        align-items: center;
        display: flex;
        max-height: 800px;
        margin: 0 auto;
        min-height: 400px;
        max-width: 2000px;
        height: calc(90vh - 100px);
        padding-bottom: 30px;

        background-image: url(../img/banner_background_800w.jpg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        text-shadow: 2px 2px 15px rgba(darken($blue, 30), 1);

        @media (min-width: 768px) {

            background-image: url(../img/banner_background_2.jpg);
        }
    }

    &__title {

        margin-bottom: 10px;

        font-weight: 900;
        font-size: 2.5rem;
        line-height: 1;

        @include media-breakpoint-up(md) {
            max-width: 600px;
            font-size: 3rem;
        }

        @include media-breakpoint-up(lg) {

            max-width: 700px;

            font-size: 4rem;
        }

        @include media-breakpoint-up(xl) {

            max-width: 780px;

            font-size: 4.75rem;
            line-height: 1.05;
        }
    }

    &__copy {

        @extend .h2;

        margin-bottom: 10px;
        margin-top: 0;

        font-weight: 500;
        letter-spacing: .02em;

        @include media-breakpoint-up(md) {

            max-width: 500px;
            margin-bottom: 40px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 100%;
        }
    }

    .btn {
        text-shadow: none;
    }
}