.site-header {

    position: fixed;
    top: 0;
    z-index: 10000;

    width: 100%;

    background-color: #fff;
    border-bottom: 1px solid #eee;
    color: $blue;

    &__inner {

        display: flex;
        justify-content: space-between;

        padding: 10px 0;

        @include media-breakpoint-down(sm) {

            flex-wrap: wrap;

            text-align: center;
        }

        @media (min-height: 600px) {

            padding: 20px 0;
        }
    }

    &__logo {

        margin: 0 auto;
        width: 40%;

        align-self: flex-start;
        display: inline-block;

        @include media-breakpoint-up(sm) {
            width: 50%;
        }
    }

    &__logo-img-wrap {

        max-width: 120px;

        @include media-breakpoint-up(sm) {
            max-width: 202px;
        }
    }

    &__logo-img {

        height: auto;
        min-width: 100px;
        max-width: 100%;
    }

    &__actions {

        align-self: flex-end;
        width: 60%;

        text-align: right;

        @include media-breakpoint-up(sm) {

            width: 50%;
        }
    }

    &__phone {

        margin-right: 20px;

        font-weight: 700;
        text-decoration: none;
    }

    &__phone-number {

        position: relative;
        top: 4px;

        display: none;

        font-size: 1.6rem;
        color: $blue;

        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }

    &__phone-icon {

        position: absolute;

        display: inline-block;
        margin-left: -10px;
        margin-top: 2px;

        #icon {
            fill: $brand-primary;
            transform: scale(.55)
        }

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__cta {

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }
}