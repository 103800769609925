.associations {

    position: relative;
    z-index: 5;

    padding-top: $spacer*1.75;

    background-color: $blue-dark;
    text-align: center;

    &__heading {

        font-weight: 500;
        opacity: .25;
        letter-spacing: .09em;
        text-transform: uppercase;
    }

    &__list {

        @extend %list-clean;

        margin: 0 auto;
        max-width: 1050px;

        @supports (display: flex) {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__item {

        display: inline-block;
        max-width: 200px;
        padding: 20px;

        opacity: .75;

        &:hover, &:focus {

            outline: 0;
            opacity: 1;
        }

        &--lo {


        }

        &--ia {


        }

        &--clia {

            max-width: 130px;
        }

        &--cic {

            max-width: 130px;
        }
    }

    &__img {

        max-width: 100%;
    }
}