.note {

    position: relative;
    z-index: 5;

    padding: 0 0 $spacer*2 0;

    background-color: lighten($brand-primary, 5);

    &__heading {

        @include antialias;

        margin-top: $spacer * .75;

        font-weight: 900;
    }

    &__copy {


    }
}