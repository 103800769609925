.detail-item {

    position: relative;
    z-index: 2;

    margin-bottom: calc(20px + 5vh);
    padding-left: 25px;

    text-shadow: 2px 2px 15px rgba(darken($green, 30), .2);

    @include media-breakpoint-up(md) {
        padding-left: 0;
    }

    @include media-breakpoint-up(lg) {
        text-shadow: 2px 2px 15px rgba(darken($green, 30), 1);
    }

    &__subtitle {

        @extend .h5;

        position: relative;

        margin-bottom: 20px;

        text-transform: uppercase;

        &:before {

            left: -30px;
            position: absolute;
            bottom: -19px;

            content: '';
            height: 20px;
            width: 20px;

            background-image: radial-gradient(#fff, #fff 2px, rgba(#fff, 0) 3px);
            border: 2px #fff solid;
            border-radius: 50%;

            @include media-breakpoint-up(md) {
                left: -60px;
            }
        }

        &:after {

            right: 0;
            position: absolute;
            bottom: -10px;

            content: '';
            width: calc(100% + 10px);

            border-bottom: 2px solid #fff;

            @include media-breakpoint-up(md) {
                width: calc(100% + 40px);
            }
        }
    }

    &__title {

        font-weight: 900;
        font-style: italic;
        letter-spacing: .04em;

        @include media-breakpoint-up(lg) {
            font-size: 2em;
            line-height: 1.325;
        }
    }

    &__copy {

    }
}