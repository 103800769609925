.contact {

    position: relative;
    z-index: 5;

    overflow: auto;
    padding: calc(20px + 5vh) 0;

    background-color: #fff;
    color: $blue-dark;
}