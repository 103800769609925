/*
|--------------------------------------------------------------------------
| List Clean
|
| Pull this into blocks and elements as needed. Cleans default styling
| for lists.
|--------------------------------------------------------------------------
*/

%list-clean {
    list-style: none;
    margin: 0;
    padding: 0;
}
