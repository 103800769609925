/*
|--------------------------------------------------------------------------
| Buttons
|
| Various button styles that can be used. Apply this class directly
| in HTML. Then BEM syntax can override if needed.
|
| Example:
|
| <div class="block">
|     <a class="block__button button" href=""></a>
| </div>
|
|--------------------------------------------------------------------------
*/

.btn {
    text-decoration: none;
}

.btn-large {

    padding: 1em 2em;

    letter-spacing: .08em;

    @include media-breakpoint-up(md) {
        font-size: 20px;
    }

}