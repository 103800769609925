.main-content {

    position: relative;

    background-color: $green;
    background-repeat: no-repeat;
    background-position: center top;

    @include media-breakpoint-up(md) {

        padding-top: 80px;

        background-image: url(../img/content_background.jpg);
        background-size: cover;
    }

    @media (min-width: 1025px) { // hack because iOS doesn't like background-attachment: fixed

        background-attachment: fixed;
        background-position: center bottom;
        background-size: auto;
    }
}