// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.


// Table of Contents
//
// Colors
// Options
// Spacing
// Body
// Links
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components


// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.


// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$gray-dark:                 #373a3c;
$gray:                      #55595c;
$gray-light:                #818a91;
$gray-lighter:              #eceeef;
$gray-lightest:             #f7f7f9;
$blue:                      #43addb;
$blue-dark:                 #19487f;
$orange:                    #ef7c39;
$green:                     #a6ba50;

$brand-primary:             $blue;
$brand-success:             $green;
$brand-info:                $blue-dark;
$brand-warning:             $orange;
$brand-danger:              $orange;
$brand-inverse:             $gray-dark;


$spacer:   1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: $spacer-x,
    y: $spacer-y
  ),
  2: (
    x: ($spacer-x * 1.5),
    y: ($spacer-y * 1.5)
  ),
  3: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  )
);
$border-width: 1px;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-flex:               false;
$enable-rounded:            true;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        false;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles:       true;


// Body
//
// Settings for the `<body>` element.

$body-bg:    #fff;
$body-color: #000;


// Links
//
// Style anchor elements.

$link-color:            $brand-primary;
$link-decoration:       none;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: underline;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Myriad W01", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-serif:      Georgia, "Times New Roman", Times, serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root: 16px;

$font-size-base: 1rem;
$font-size-lg:   1.25rem;
$font-size-sm:   .875rem;
$font-size-xs:   .75rem;

$line-height-base: 1.5;

$font-size-h1: 2.5rem;
$font-size-h2: 2rem;
$font-size-h3: 1.75rem;
$font-size-h4: 1.5rem;
$font-size-h5: 1.25rem;
$font-size-h6: 1rem;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight: 900;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;

$headings-margin-bottom: ($spacer / 2);
$headings-font-family:   inherit;
$headings-font-weight:   900;
$headings-line-height:   1.1;
$headings-color:         inherit;

$lead-font-size:   1.25rem;
$lead-font-weight: 300;

$small-font-size: 80%;

$text-muted: $gray-light;

$abbr-border-color: $gray-light;

$blockquote-small-color:  $gray-light;
$blockquote-font-size:    ($font-size-base * 1.25);
$blockquote-border-color: $gray-lighter;
$blockquote-border-width: .25rem;

$hr-border-color: rgba(0,0,0,.1);
$hr-border-width: $border-width;

$mark-padding: .2em;

$dt-font-weight: bold;

$kbd-box-shadow:         inset 0 -.1rem 0 rgba(0,0,0,.25);
$nested-kbd-font-weight: bold;

$list-inline-padding: 5px;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:         (4 / 3);
$line-height-sm:         1.5;

$border-radius:          0;
$border-radius-lg:       0;
$border-radius-sm:       0;

$component-active-color: #fff;
$component-active-bg:    $brand-primary;

$caret-width:            .3em;
$caret-width-lg:         $caret-width;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .75rem;
$table-sm-cell-padding:         .3rem;

$table-bg:                      transparent;
$table-bg-accent:               rgba(0,0,0,.05);
$table-bg-hover:                rgba(0,0,0,.075);
$table-bg-active:               $table-bg-hover;

$table-border-width:            $border-width;
$table-border-color:            $gray-lighter;


// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-padding-x:                  1rem;
$btn-padding-y:                  .5rem;
$btn-line-height:                1.25;
$btn-font-weight:                normal;
$btn-box-shadow:                 inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
$btn-active-box-shadow:          inset 0 3px 5px rgba(0,0,0,.125);

$btn-primary-color:              #fff;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $btn-primary-bg;

$btn-secondary-color:            $gray-dark;
$btn-secondary-bg:               #fff;
$btn-secondary-border:           #ccc;

$btn-info-color:                 #fff;
$btn-info-bg:                    $brand-info;
$btn-info-border:                $btn-info-bg;

$btn-success-color:              #fff;
$btn-success-bg:                 $brand-success;
$btn-success-border:             $btn-success-bg;

$btn-warning-color:              #fff;
$btn-warning-bg:                 $brand-warning;
$btn-warning-border:             $btn-warning-bg;

$btn-danger-color:               #fff;
$btn-danger-bg:                  $brand-danger;
$btn-danger-border:              $btn-danger-bg;

$btn-link-disabled-color:        $gray-light;

$btn-padding-x-sm:               .5rem;
$btn-padding-y-sm:               .25rem;

$btn-padding-x-lg:               2.5rem;
$btn-padding-y-lg:               .75rem;

$btn-block-spacing-y:            .5rem;
$btn-toolbar-margin:             .5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:              $border-radius;
$btn-border-radius-lg:           $border-radius-lg;
$btn-border-radius-sm:           $border-radius-sm;


// Forms

$input-padding-x:                .75rem;
$input-padding-y:                .5rem;
$input-line-height:              1.25;

$input-bg:                       #eee;
$input-bg-disabled:              $gray-lighter;

$input-color:                    $gray;
$input-border-color:             rgba(0,0,0,.15);
$input-btn-border-width:         0;
$input-box-shadow:               0;

$input-border-radius:            $border-radius;
$input-border-radius-lg:         $border-radius-lg;
$input-border-radius-sm:         $border-radius-sm;

$input-bg-focus:                 $input-bg;
$input-border-focus:             $gray;
$input-box-shadow-focus:         $input-box-shadow, 0 0 8px rgba(102,175,233,.6);
$input-color-focus:              $input-color;

$input-color-placeholder:        #999;

$input-padding-x-sm:             .5rem;
$input-padding-y-sm:             .25rem;

$input-padding-x-lg:             1.5rem;
$input-padding-y-lg:             .75rem;

$input-height:                   (($font-size-base * $line-height-base) + ($input-padding-y * 2));
$input-height-lg:                (($font-size-lg * $line-height-lg) + ($input-padding-y-lg * 4));
$input-height-sm:                (($font-size-sm * $line-height-sm) + ($input-padding-y-sm * 2));

$form-group-margin-bottom:       $spacer-y;

$input-group-addon-bg:           $gray-lighter;
$input-group-addon-border-color: $input-border-color;

$cursor-disabled:                not-allowed;

$custom-control-gutter:   1.5rem;
$custom-control-spacer-x: 1rem;
$custom-control-spacer-y: .25rem;

$custom-control-indicator-size:       1rem;
$custom-control-indicator-bg:         #ddd;
$custom-control-indicator-bg-size:    50% 50%;
$custom-control-indicator-box-shadow: inset 0 .25rem .25rem rgba(0,0,0,.1);

$custom-control-disabled-cursor:             $cursor-disabled;
$custom-control-disabled-indicator-bg:       #eee;
$custom-control-disabled-description-color:  #767676;

$custom-control-checked-indicator-color:      #fff;
$custom-control-checked-indicator-bg:         #0074d9;
$custom-control-checked-indicator-box-shadow: none;

$custom-control-focus-indicator-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;

$custom-control-active-indicator-color:      #fff;
$custom-control-active-indicator-bg:         #84c6ff;
$custom-control-active-indicator-box-shadow: none;

$custom-checkbox-radius: $border-radius;
$custom-checkbox-checked-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-checked-indicator-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");

$custom-checkbox-indeterminate-bg: #0074d9;
$custom-checkbox-indeterminate-indicator-color: $custom-control-checked-indicator-color;
$custom-checkbox-indeterminate-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indeterminate-indicator-color}' d='M0 2h4'/%3E%3C/svg%3E");
$custom-checkbox-indeterminate-box-shadow: none;

$custom-radio-radius: 50%;
$custom-radio-checked-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-checked-indicator-color}'/%3E%3C/svg%3E");

$custom-select-padding-x:          .75rem ;
$custom-select-padding-y:          .375rem;
$custom-select-indicator-padding:  1rem; // Extra padding to account for the presence of the background-image based indicator
$custom-select-color:          $input-color;
$custom-select-disabled-color: $gray-light;
$custom-select-bg:            #fff;
$custom-select-disabled-bg:   $gray-lighter;
$custom-select-bg-size:       8px 10px; // In pixels because image dimensions
$custom-select-indicator-color: #333;
$custom-select-indicator:     url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
$custom-select-border-width:  $input-btn-border-width;
$custom-select-border-color:  $input-border-color;
$custom-select-border-radius: $border-radius;

$custom-select-focus-border-color: #51a7e8;
$custom-select-focus-box-shadow:   inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 5px rgba(81, 167, 232, .5);

$custom-select-sm-padding-y: .2rem;
$custom-select-sm-font-size: 75%;

$custom-file-height:           2.5rem;
$custom-file-width:            14rem;
$custom-file-focus-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;

$custom-file-padding-x:     .5rem;
$custom-file-padding-y:     1rem;
$custom-file-line-height:   1.5;
$custom-file-color:         #555;
$custom-file-bg:            #fff;
$custom-file-border-width:  $border-width;
$custom-file-border-color:  #ddd;
$custom-file-border-radius: $border-radius;
$custom-file-box-shadow:    inset 0 .2rem .4rem rgba(0,0,0,.05);
$custom-file-button-color:  $custom-file-color;
$custom-file-button-bg:     #eee;
$custom-file-text: (
  placeholder: (
    en: "Choose file..."
  ),
  button-label: (
    en: "Browse"
  )
);


// Form validation icons
$form-icon-success-color: $brand-success;
$form-icon-success: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-icon-success-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");

$form-icon-warning-color: $brand-warning;
$form-icon-warning: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-icon-warning-color}' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");

$form-icon-danger-color: $brand-danger;
$form-icon-danger: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-icon-danger-color}' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:             10rem;
$dropdown-padding-y:             .5rem;
$dropdown-margin-top:            .125rem;
$dropdown-bg:                    #fff;
$dropdown-border-color:          rgba(0,0,0,.15);
$dropdown-border-width:          $border-width;
$dropdown-divider-bg:            #e5e5e5;
$dropdown-box-shadow:            0 .5rem 1rem rgba(0,0,0,.175);

$dropdown-link-color:            $gray-dark;
$dropdown-link-hover-color:      darken($gray-dark, 5%);
$dropdown-link-hover-bg:         #f5f5f5;

$dropdown-link-active-color:     $component-active-color;
$dropdown-link-active-bg:        $component-active-bg;

$dropdown-link-disabled-color:   $gray-light;

$dropdown-item-padding-x:        1.5rem;

$dropdown-header-color:          $gray-light;



// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.

$state-success-text:             #3c763d;
$state-success-bg:               #dff0d8;
$state-success-border:           darken($state-success-bg, 5%);

$state-info-text:                #31708f;
$state-info-bg:                  #d9edf7;
$state-info-border:              darken($state-info-bg, 7%);

$state-warning-text:             #8a6d3b;
$state-warning-bg:               #fcf8e3;
$mark-bg:                        $state-warning-bg;
$state-warning-border:           darken($state-warning-bg, 5%);

$state-danger-text:              #a94442;
$state-danger-bg:                #f2dede;
$state-danger-border:            darken($state-danger-bg, 5%);
