/*
|--------------------------------------------------------------------------
| Base Typography
|
| These styles are used to set a base style for all elements. Only use
| these properties here:
|
| color:
| font-family:
| font-size:
| line-height:
| margin:
| padding:
|
|--------------------------------------------------------------------------
*/

html {
    line-height: 1.6;
}

h1 {
    @include antialias;

    margin-bottom: 0.5em;
    margin-top: 1em;

    font-size: 4em;
    line-height: 1.2;
}

h2 {
    @include antialias;
    margin-bottom: 0.2em;
    margin-top: 1em;

    font-size: 2em;
    line-height: 1.35;
}

h3 {
    @include antialias;
    margin-bottom: 0.2em;
    margin-top: 0.5em;

    font-size: 1.5em;
    line-height: 1.35;
}

h4, h5, h6 {
    margin-bottom: 0.2em;
    margin-top: 0.5em;

    font-size: 0.9em;
    line-height: 1.2;
}

p {
    margin-bottom: 0.6em;
    margin-top: 0;
}

ul {
    margin-bottom: 1.5em;
    margin-left: 1.5em;
    margin-top: 1.5em;
}

ol {
    margin-bottom: 1.5em;
    margin-left: 1.5em;
    margin-top: 1.5em;
}

li {
    margin-bottom: 0.8em;
    margin-left: 1em;
}

b, strong {
    font-weight: bold;
}

i, em {
    font-style: italic;
}

quote, blockquote {
    margin: 20px;
}

.nowrap {
    white-space: nowrap;
}
