.notes {

    position: relative;
    z-index: 5;

    background-color: lighten($brand-primary, 5);

    &__heading {

        @include antialias;

        margin-bottom: 0;
        padding-top: $spacer;

        color: $blue-dark;
        font-weight: 900;
    }

    &__copy {


    }
}