.about-company {

    position: relative;
    z-index: 5;

    padding: $spacer 0;

    background-color: darken($blue, 2);
    text-align: center;

    &__copy {


    }
}